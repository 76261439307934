import accordion from './accordion'
import dismiss from './dismiss'
import collapse from './collapse'
import dropdown from './dropdown'
import modal from './modal'
import tabs from './tabs'
import { getTarget, getDuration, addEventListeners, addEscapeListener, handleResize} from '../utils'


document.addEventListener('DOMContentLoaded', () => {
  accordion()
  collapse()
  dismiss()
  dropdown()
  modal()
  tabs()

  handleResize()
})

if (document.readyState === "complete") {
  window.document.dispatchEvent(new Event("DOMContentLoaded", {
    bubbles: true,
    cancelable: true
  }))
}
