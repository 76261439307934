import { getTarget, getDuration, addEventListeners, addEscapeListener} from '../utils'

export default () => document.querySelectorAll('[data-toggle="accordion"]').forEach(element => {
  element.addEventListener('click', () => {
    let target = element.closest('.accordion-item').querySelector('.accordion-collapse')
    const duration = getDuration(target)

    target.style.overflow = 'hidden'
    target.style.height = 0

    if (element.getAttribute('aria-expanded') === 'true') {
      element.setAttribute('aria-expanded', 'false');
      target.classList.remove('show');

      setTimeout(() => {
        target.classList.remove('block');
        target.classList.add('hidden');
      }, duration)
    }
    else {
      element.setAttribute('aria-expanded', 'true');
      target.classList.add('block');
      target.classList.add('show');
      target.classList.remove('hidden');

      setTimeout(() => {
        let navHeight = target.scrollHeight
        target.style.height = navHeight + 'px'
      }, 50)

      setTimeout(() => {
        target.style.overflow = ''
      }, duration)
    }
  });
});
