export default () => document.querySelectorAll('[role="tab"]').forEach(element => {
  element.addEventListener('click', () => {
    if (element.getAttribute("aria-selected") === 'true') {
      return null
    }

    let targetElement = document.querySelector(`[aria-labelledby="${element.getAttribute('aria-controls')}"]`)
    if (!targetElement) {
      return null
    }

    let inactiveClass = element.classList.value

    let tablist = element.parentNode
    while (tablist && tablist.getAttribute('role') !== 'tablist') {
      tablist = tablist.parentNode
    }

    let activeTabs = tablist.querySelectorAll('[aria-selected="true"]')
    if (!activeTabs || activeTabs.length === 0) {
      return null
    }
    let activeClass = activeTabs[0].classList.value;

    tablist.querySelectorAll('[role="tab"]').forEach(tab => {
      tab.classList = inactiveClass
      tab.setAttribute('aria-selected', 'false')
    });

    element.classList = activeClass;
    element.setAttribute('aria-selected', 'true');

    if (targetElement.getAttribute('role') === 'tabcontent') {
      [...targetElement.children].forEach(n => {
        if (n.getAttribute('role') === 'tabpanel') {
          n.classList.remove('hidden')
        }
      })
      return
    }

    [...targetElement.parentNode.children].forEach(n => {
      if (n.getAttribute('role') === 'tabpanel') {
        n.classList.add('hidden')
      }
    })

    targetElement.classList.remove('hidden')
  })
});
