import { getDuration, getTarget } from '../utils'

const dismiss = element => {
  let target = getTarget(element)
  if (!target) {
    target = element.closest(`.${element.getAttribute('data-dismiss')}`)
  }
  target.classList.add('opacity-0')
  setTimeout(() => target.remove(), getDuration(target))
}

export default () => document.querySelectorAll('[data-dismiss]').forEach(element => {
  element.addEventListener('click', () => dismiss(element))
})
