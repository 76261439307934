import { waitForElement, parseElementOptions } from '../utils'


const addModal = (element) => {
  const options = parseElementOptions(element)
  const {size, beforeShown} = options
  const id = options.id || 'v-modal'

  let content = ''
  if (options.imgSrc) {
    content = `<img src="${options.imgSrc}">`
  }
  if (options.iframeSrc) {
    content = `<iframe allow="autoplay" class="aspect-video w-full" src="${options.iframeSrc}" allowfullscreen="" autoplay=""></iframe>`
  }
  if (element.dataset.html) {
    content = element.dataset.html
  }

  let modalHTML = `<div id="${id}" class="modal">
  <div class="modal-content ${size && `modal-${size}`}">
    ${content}
  </div>

  <button onclick="removeModal('${id}')" type="button" class="fixed right-0 top-0 z-50 text-white px-5 close" data-dismiss="modal" aria-label="Close">
    <span class="text-4xl" aria-hidden="true">&times;</span>
  </button>

  <div onclick="removeModal('${id}')" class="modal-bg"></div>
</div>
`;

  document.addEventListener('keydown', e => e.keyCode == 27 && window.removeModal(id))
  document.body.style.overflow = 'hidden'
  document.body.insertAdjacentHTML('beforeend', modalHTML)

  waitForElement('#' + id).then(ele => {
    beforeShown && window[beforeShown]()
    setTimeout(() => ele.classList.add('opacity-100'), 32)
  })
}

const removeModal = (id='v-modal') => {
  let modal = document.getElementById(id)
  modal.classList.remove('opacity-100')
  setTimeout(() => {
    modal.remove()
    document.body.style.overflow = ''
  }, 500)
}

export default () => document.querySelectorAll('[data-toggle="modal"]').forEach(element => {
  if (element.getAttribute('aria-label')) {
    return
  }
  element.setAttribute('aria-label', 'modal')
  window.removeModal = removeModal
  element.addEventListener('click', () => addModal(element))
})
